<!-- 店铺中心详情页面 -->
<template>
  <div class="shopDetail-container">
    <el-drawer class="shopDetail-drawer" title="店铺详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose" >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="drawer-container">
        <div class="drawer-left">
          <div class="order">
            订单编号: 
            <el-select style="margin-left: 16px;" v-model="shopOrderId" @change="changeOrder" placeholder="请选择">
              <el-option
                v-for="item in shopOrder"
                :key="item.id"
                :label="item.orderNo + '\u3000\u3000' + item.time"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="line"></div>
          <div class="left-top">
            <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm">
              <div class="detail-title">
                <div class="tit-info">
                    <div class="shopLogo-container">
                      <img @click="logoFun" v-if="shopInfo.shopLogo" :src="shopInfo.shopLogo" alt="">
                      <i @click="logoFun" v-else class="el-icon-user common-iconImg-MR"></i>
                      <input style="width: 0;display: none;" ref="uploadLogo" type="file" @input="chooseFile">
                    </div>
                    <div class="shopName-custName">
                      <span>{{ shopInfo.shopName || "--" }}</span>
                      <span>{{ shopInfo.customerName || "--" }}</span>
                    </div>
                    <div v-if="shopInfo.state == 0" class="common-tableStatus-red">服务终止</div>
                    <div v-if="shopInfo.state == 1" class="common-tableStatus-green">正在服务</div>
                    <div v-if="shopInfo.state == 2" class="common-tableStatus-blue">待服务</div>
                    <div v-if="shopInfo.cmsSubaccount == 0" class="common-tableStatus-red" style="margin-left: 16px">未分配客服</div>
                    <div v-if="shopInfo.cmsSubaccount == 1" class="common-tableStatus-blue" style="margin-left: 16px">已分配客服</div>
                </div>
              </div>
              <div class="detail-content">
                <el-form-item v-if="editParams.filedName == 'shopName'" class="edit-input-container" prop="shopName" label="店铺名称:">
                  <el-input class="edit-input" placeholder="请输入内容" ref="shopName" v-model="editRuleForm.shopName" @blur="() => {editCancelBtn('shopName')}">
                    <template slot="append"><div @click="() => {editShopDetail('shopName')}" class="cancel">确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('shopName', shopInfo.shopName)}" v-if="editParams.filedName != 'shopName'" class="hoverEdit">
                  店铺名称:
                  <span class="editColor">{{(shopInfo.shopName) || "--"}}</span>
                </div>

                <!-- <el-form-item v-if="editParams.filedName == 'platId'" class="edit-input-container" prop="platId" label="平台/类目:">
                  <el-cascader :options="platList" class="edit-input" ref="platId" :props="{ label: 'platformName', value: 'id' }" v-model="editRuleForm.platId" @blur="() => {editCancelBtn('platId')}" @change="(e) => {editShopDetail('platId')}" filterable></el-cascader>
                </el-form-item>
                <div @click="() => {editBtn('platId', shopInfo.platId)}" v-if="editParams.filedName != 'platId'" class="hoverEdit">
                  平台/类目:
                  <span class="editColor">{{(shopInfo.platformName) ? shopInfo.categoryName ? (shopInfo.platformName + '/' + shopInfo.categoryName) :  shopInfo.platformName : "--"}}</span>
                </div> -->

                <el-form-item v-if="editParams.filedName == 'platformId'" class="edit-input-container editInputContainer" prop="platformId" label="所属平台:">
                  <el-select ref="platformId" v-model="editRuleForm.platformId" placeholder="请选择" @blur="() => {editCancelBtn('platformId')}" @change=" (e) => { editShopDetail('platformId') } " filterable>
                    <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" ></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('platformId', shopInfo.platformId)}" v-if="editParams.filedName != 'platformId'" class="hoverEdit">
                  所属平台:
                  <span class="editColor">{{(shopInfo.platformName) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'categoryId'" class="edit-input-container editInputContainer" prop="categoryId" label="所属类目:">
                  <el-select ref="categoryId" class="common-screen-input" v-model="editRuleForm.categoryId" placeholder="所属类目" @blur="() => {editCancelBtn('categoryId')}" @change=" (e) => { editShopDetail('categoryId') } " filterable remote :remote-method="(val)=>{remoteCategory(val)}">
                    <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('categoryId', shopInfo.categoryId)}" v-if="editParams.filedName != 'categoryId'" class="hoverEdit">
                  所属类目:
                  <span class="editColor">{{(shopInfo.categoryName) || "--"}}</span>
                </div>

                <div class="hoverEdit">
                  店铺金额:
                  <span class="editColor" @click="()=>{editShow()}">
                    {{(shopInfo.price) ? Number(shopInfo.price).toFixed(2) : "--"}}
                  </span>
                </div>
                
                <el-form-item v-if="editParams.filedName == 'agreeSubNum'" class="edit-input-container" prop="agreeSubNum" label="协议子账号数:">
                  <el-input class="edit-input" placeholder="请输入内容" ref="agreeSubNum" type="number"  @mousewheel.native.prevent v-model="editRuleForm.agreeSubNum" @blur="() => {editCancelBtn('agreeSubNum')}">
                    <template slot="append"><div @click="() => {editShopDetail('agreeSubNum')}" class="cancel">确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('agreeSubNum', shopInfo.agreeSubNum)}" v-if="editParams.filedName != 'agreeSubNum'" class="hoverEdit">
                  协议子账号数:
                  <span class="editColor">{{(shopInfo.agreeSubNum) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'conslutDayNum'" class="edit-input-container" prop="conslutDayNum" label="协议单账号日均咨询量:">
                  <el-input class="edit-input" placeholder="请输入内容" ref="conslutDayNum" type="number"  @mousewheel.native.prevent v-model="editRuleForm.conslutDayNum" @blur="() => {editCancelBtn('conslutDayNum')}">
                    <template slot="append"><div @click="() => {editShopDetail('conslutDayNum')}" class="cancel">确认</div></template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('conslutDayNum', shopInfo.conslutDayNum)}" v-if="editParams.filedName != 'conslutDayNum'" class="hoverEdit">
                  协议单账号日均咨询量:
                  <span class="editColor">{{(shopInfo.conslutDayNum) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'startTime'" class="edit-input-container" prop="startTime" label="开始时间:">
                  <el-date-picker :picker-options="pickerStart" class="edit-input"  ref="startTime" v-model="editRuleForm.startTime" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @blur="() => {editCancelBtn('startTime')}" @change="(e) => {editShopDetail('startTime')}" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <div @click="() => {editBtn('startTime', DateTransform(shopInfo.startTime))}" v-if="editParams.filedName != 'startTime'" class="hoverEdit">
                  开始时间:
                  <span class="editColor">{{ (DateTransform(shopInfo.startTime)) || "--" }}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'endTime'" class="edit-input-container" prop="endTime" label="结束时间:">
                  <el-date-picker :picker-options="pickerEnd" class="edit-input" ref="endTime" v-model="editRuleForm.endTime" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @blur="() => {editCancelBtn('endTime')}" @change="(e) => {editShopDetail('endTime')}" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <div @click="() => {editBtn('endTime', DateTransform(shopInfo.endTime))}" v-if="editParams.filedName != 'endTime'" class="hoverEdit">
                  结束时间:
                  <span class="editColor">{{ (DateTransform(shopInfo.endTime)) || "--" }}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'contactName'" class="edit-input-container editInputContainer" prop="contactName" label="联系人:">
                  <el-input class="edit-input" ref="contactName" placeholder="请输入内容" v-model="editRuleForm.contactName" @blur="() => {editCancelBtn('contactName')}">
                    <template slot="append">
                      <div @click="() => {editShopDetail('contactName')}" class="cancel">确认</div>
                    </template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('contactName', shopInfo.contactName)}" v-if="editParams.filedName != 'contactName'" class="hoverEdit">
                  联系人:
                  <span class="editColor">{{(shopInfo.contactName) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'contactPhone'" class="edit-input-container editInputContainer" prop="contactPhone" label="联系方式:">
                  <el-input class="edit-input" ref="contactPhone" placeholder="请输入内容" v-model="editRuleForm.contactPhone"  @mousewheel.native.prevent @blur="() => {editCancelBtn('contactPhone')}">
                    <template slot="append">
                      <div @click="() => {editShopDetail('contactPhone')}" class="cancel">确认</div>
                    </template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('contactPhone', shopInfo.contactPhone)}" v-if="editParams.filedName != 'contactPhone'" class="hoverEdit">
                  联系方式:
                  <span class="editColor">{{(shopInfo.contactPhone) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'busineseUserIds'" class="edit-input-container editInputContainer" prop="busineseUserIds" label="运营负责:">
                  <el-select ref="busineseUserIds" v-model="editRuleForm.busineseUserIds" placeholder="请选择" @visible-change="(isShow) => { editShopDetail('busineseUserIds', isShow) }" filterable multiple>
                    <el-option v-for="item in userList" :key="item.id" :label="item.nickName" :value="item.id + ''" ></el-option>
                  </el-select>
                </el-form-item>
                <div @click="() => {editBtn('busineseUserIds', shopInfo.busineseUserIds)}" v-if="editParams.filedName != 'busineseUserIds'" class="hoverEdit">
                  运营负责:
                  <span class="editColor">{{(shopInfo.busineseUser) || "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'beforeDay'" class="edit-input-container editInputContainer" prop="beforeDay" label="预警时间:">
                  <el-radio-group ref="beforeDay" v-model="editRuleForm.beforeDay" @change="() => { editShopDetail('beforeDay')}">
                    <el-radio-button label="3">提前3天</el-radio-button>
                    <el-radio-button label="7">提前7天</el-radio-button>
                    <el-radio-button label="15">提前15天</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div @click="() => {editBtn('beforeDay', shopInfo.beforeDay)}" v-if="editParams.filedName != 'beforeDay'" class="hoverEdit">
                  预警时间:
                  <span class="editColor">{{(shopInfo.beforeDay) ? '提前' + shopInfo.beforeDay + '天' : "--"}}</span>
                </div>

                <el-form-item v-if="editParams.filedName == 'warnTime'" class="edit-input-container editInputContainer" prop="warnTime" label="当日发送时间:">
                    <el-time-picker ref="warnTime" v-model="editRuleForm.warnTime" format="HH:mm" value-format="HH:mm" @change="() => { editShopDetail('warnTime')}" placeholder="请选择时间" ></el-time-picker>
                </el-form-item>
                <div @click="() => {editBtn('warnTime', shopInfo.warnTime)}" v-if="editParams.filedName != 'warnTime'" class="hoverEdit">
                  当日发送时间:
                  <span class="editColor">{{(shopInfo.warnTime) ? shopInfo.warnTime : "--"}}</span>
                </div>

              </div>
            </el-form>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">上传文档<input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteFile(item)">
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>
          <el-tabs class="tabsDetail" v-model="activeTab" @tab-click="handleClick">
            <el-tab-pane label="店铺订单" name="1">
              <el-table class="common-table" :data="orderTable" :fit="true">
                <el-table-column label="订单编号" width="140" fixed="left">
                  <template slot-scope="scope">
                    <div class="newLable">
                      <!-- <el-button style="white-space: normal;text-align: left" type="text" size="small" @click="() => { $router.push({ path: '/customer/total-order', query: {from: 'customer', orderNo: scope.row.orderNo} })}">{{scope.row.orderNo || '--'}}</el-button> -->
                      <el-button style="white-space: normal;text-align: left" type="text" size="small" @click="() => {
                        openNewWin(`/customer/total-order?orderNo=${scope.row.orderNo}&orderId=${scope.row.id}&from=customer`)
                      }">{{scope.row.orderNo || '--'}}</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="orderName"
                  label="订单名称"
                  :formatter="tableColumn"
                  width="140px"
                ></el-table-column>
                <el-table-column
                  prop="orderStep"
                  label="服务阶段"
                  :formatter="tableColumn"
                  width="120px"
                ></el-table-column>
                <el-table-column label="服务时间" width="120px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">
                      {{
                        `${DateTransform(scope.row.startDate)}\n${DateTransform(scope.row.endDate)}`
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="订单状态" width="120px">
                  <template slot-scope="scope">
                    <div>
                      <div v-if="scope.row.status == 'process'" class="common-tableStatus-red">续签预警</div>
                      <div v-if="scope.row.status == 'setFail'" class="common-tableStatus-grey">续签失败</div>
                      <div v-if="scope.row.status == 'complete'" class="common-tableStatus-blue">续签成功</div>
                      <div v-if="scope.row.status == 'servicing'" class="common-tableStatus-green">正在服务</div>
                      <div v-if="scope.row.status == 'fail'" class="common-tableStatus-orange">续签超时</div>
                      <div v-if="scope.row.status == 'setDelete'" class="common-tableStatus-grey">订单作废</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="收款状态" width="120px">
                  <template slot-scope="scope">
                    <div v-if="scope.row.financialStatus">
                      <div v-if="scope.row.financialStatus == 'pending'" style="color: #F98787;">待销售催款</div>
                      <div v-if="scope.row.financialStatus == 'confirming'" style="color: #F09009;">待财务确认</div>
                      <div v-if="scope.row.financialStatus == 'complete'" style="color: #1890FF;">交易完成</div>
                    </div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="应收金额" :formatter="tableColumn" width="120px"></el-table-column>
                <el-table-column prop="collectionAmount" label="实收金额" :formatter="tableColumn" width="120px" ></el-table-column>
                <el-table-column prop="uncollectionAmount" label="未收金额" :formatter="tableColumn" width="120px" ></el-table-column>
                <el-table-column prop="createName" label="销售负责人" :formatter="tableColumn" width="160px"></el-table-column>
                <el-table-column prop="createTime" label="创建人" :formatter="tableColumn" width="160px" ></el-table-column>
                <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn" width="180px" ></el-table-column>
                <el-table-column prop="note" label="备注" :formatter="tableColumn" width="160px" show-overflow-tooltip ></el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunOrder(e, 'pageSize')"
                @current-change="(e) => pageFunOrder(e)"
                style="text-align: center"
                :page-sizes="paginationOrder.pageSizes"
                :page-size="orderParams.pageSize"
                :current-page="orderParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationOrder.total"></el-pagination>
                <br/>
            </el-tab-pane>
            <el-tab-pane label="子账号列表" name="4">
              <div style="text-align: right;"><el-button @click="() => {
                $router.push({
                  path: '/customer/company-shop-list/subaccount-list',
                  query: {
                    id: shopInfo.id,
                    shopName: shopInfo.shopName,
                    companyName: shopInfo.companyName,
                    companyId: rolesCompany[0].id,
                    departName: shopInfo.departName,
                    categoryName: shopInfo.categoryName,
                    platformName: shopInfo.platformName,
                    customerName: shopInfo.customerName,
                    startTime: shopInfo.startTime,
                    endTime: shopInfo.endTime,
                    shopLogo: shopInfo.shopLogo,
                    fileUrl: shopInfo.fileUrl,
                    isAssign: shopInfo.isAssign || 0
                  },
                });
              }" class="zi" plain>子账号管理</el-button></div>
              <el-table class="common-table" :data="childData">
                <el-table-column
                  prop="subaccountName"
                  label="子账号名称"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="subaccountPassword"
                  label="子账号密码"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="phone"
                  label="管理员手机号"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="serviceName"
                  label="指派客服"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="服务时间" width="200px">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.startTime && scope.row.endTime
                        ? scope.row.startTime + " ~ " + scope.row.endTime
                        : "--"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="note"
                  label="备注"
                >
                  <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
                      <div class="common-overflow-drop">{{scope.row.note}}</div>
                    </el-tooltip>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  :formatter="tableColumn"
                  label="创建时间"
                ></el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunChild(e, 'pageSize')"
                @current-change="(e) => pageFunChild(e)"
                style="text-align: center"
                :page-sizes="childPagination.pageSizes"
                :page-size="childparams.pageSize"
                :current-page="childparams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="childPagination.total">
              </el-pagination>
              <br/>
            </el-tab-pane>
            <el-tab-pane label="历史客服" name="2">
              <el-table class="common-table" :data="serTable">
                  <el-table-column prop="nickName" label="客服名称" :formatter="tableColumn">
                    <template slot-scope="scope">
                      <div>
                        <el-button @click="() => { $router.push({path: `/personal-center?userId=${scope.row.id}`}) }" type="text">{{scope.row.nickName}}</el-button>
                    </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="account" label="客服账号" :formatter="tableColumn"></el-table-column>
                  <el-table-column prop="companyName" label="所属公司" :formatter="tableColumn"></el-table-column>
                  <el-table-column prop="departName" label="所属部门" :formatter="tableColumn"></el-table-column>
                <el-table-column label="服务时间" width="120px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">
                      {{ `${DateTransform(scope.row.startTime)}\n${DateTransform(scope.row.endTime)}` }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="createName" label="创建人" :formatter="tableColumn"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn" width="160px"></el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunSer(e, 'pageSize')"
                @current-change="(e) => pageFunSer(e)"
                style="text-align: center"
                :page-sizes="paginationSer.pageSizes"
                :page-size="serParams.pageSize"
                :current-page="serParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationSer.total"></el-pagination>
                <br/>
            </el-tab-pane>
            <el-tab-pane label="服务均值" name="3">
              <div class="common-screen-container">
                <!-- <div v-show="!selectChange" class="common-input-container">
                  <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="serveParams.startDate" :endDate="serveParams.endDate" ref="commonReset"></common-lately-date>
                </div>
                <div v-show="!selectChange" class="common-input-container">
                  <el-button class="common-screen-btn" type="primary" @click=" () => { systemServiceData()}">查 询</el-button>
                  <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
                </div> -->
                  <el-button @click="() => { openUrl() }" type="text">查看所有店铺数据</el-button>
                  <el-button @click="() => { setDialogVisible = true }" type="primary" class="set">设置目标均值</el-button>
              </div>
              <div class="tableshow">
                <span class="square no"></span><span>“--”未设置目标均值</span>
                <span class="square y"></span><span>均值数据达标</span>
                <span class="square n"></span><span>均值数据未达标</span>
              </div>
              <div class="systemServiceData-content">
                <table class="systemServiceData-table">
        <tbody>
          <tr>
            <th>咨询人数</th>
            <th>接待人数</th>
            <th>首响时间/目标均值</th>
            <th>平响时间/目标均值</th>
            <th>问答比/目标均值</th>
            <th>三分钟回复率/目标均值</th>
            <th>五分钟回复率/目标均值</th>
          </tr>
          <tr>
            <td>{{ (serveData && serveData.consultationsNum) || "--" }}</td>
            <td>{{ (serveData && serveData.receptionNum) || "--" }}</td>
            <td>
              <span :class="[(serveData&&serveData.firstTime)&&(setTableData&&setTableData.firstTime)? (Number(serveData&&serveData.firstTime)>Number(setTableData&&setTableData.firstTime)? 'fontRed':'fontGreen'):'' ]">{{ (serveData && (serveData.firstTime||serveData.firstTime=='0')) ?serveData.firstTime+ 's': "--" }}</span>
              <span>/{{setTableData&&(setTableData.firstTime||setTableData.firstTime =='0')? setTableData.firstTime + 's' : '--'}}</span>
            </td>
            <td>
              <span :class="[(serveData&&serveData.avgTime)&&(setTableData&&setTableData.avgTime)? (Number(serveData&&serveData.avgTime)>Number(setTableData&&setTableData.avgTime)? 'fontRed':'fontGreen'):'' ]">{{ (serveData && (serveData.avgTime||serveData.avgTime=='0'))? serveData.avgTime +'s': "--" }}</span>
              <span>/{{setTableData&&(setTableData.avgTime||setTableData.avgTime == '0')? setTableData.avgTime+'s' : '--'}}</span>
            </td>
            <td>
              <span :class="[(serveData&&serveData.qaRate)&&(setTableData&&setTableData.questionAnswerRatio)? (Number(serveData&&serveData.qaRate)<Number(setTableData&&setTableData.questionAnswerRatio)? 'fontRed':'fontGreen'):'' ]">{{ (serveData && (serveData.qaRate||serveData.qaRate=='0')) ? serveData.qaRate + '%' : "--" }}</span>
              <span>/{{setTableData&&(setTableData.questionAnswerRatio||setTableData.questionAnswerRatio=='0')? setTableData.questionAnswerRatio+'%': '--'}}</span>
            </td>
            <td>
              <span :class="[(serveData&&serveData.threeMinuteResponseRate)&&(setTableData&&setTableData.thereTimeRatio) ? (Number(serveData&&serveData.threeMinuteResponseRate)<Number(setTableData&&setTableData.thereTimeRatio)? 'fontRed':'fontGreen'):'' ]">{{ (serveData && (serveData.threeMinuteResponseRate||serveData.threeMinuteResponseRate=='0')) ? serveData.threeMinuteResponseRate + '%' : "--" }}</span>
              <span>/{{setTableData&&(setTableData.thereTimeRatio||setTableData.thereTimeRatio=='0')? setTableData.thereTimeRatio+'%': '--'}}</span>
            </td>
            <td>
              <span :class="[(serveData&&serveData.fiveMinuteResponseRate)&&(setTableData&&setTableData.fiveTimeRatio)? (Number(serveData&&serveData.fiveMinuteResponseRate)<Number(setTableData&&setTableData.fiveTimeRatio)? 'fontRed':'fontGreen'):'' ]">{{ (serveData && (serveData.fiveMinuteResponseRate||serveData.fiveMinuteResponseRate=='0')) ? serveData.fiveMinuteResponseRate + '%' : "--" }}</span>
              <span>/{{setTableData&&(setTableData.fiveTimeRatio||setTableData.fiveTimeRatio=='0')? setTableData.fiveTimeRatio+'%':'--'}}</span>
            </td>
          </tr>
        </tbody>
                </table>
              </div>
              <system-ser-detail ref="serDetail" :shopId="this.tableRow.id"></system-ser-detail>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="about">
              <div class="about-content">
                <textarea class="common-textarea-log"  @paste="handlePaste" v-model="recordContent" placeholder="工作小计" rows="6"></textarea>
                <!-- <el-input class="con-rec" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent"></el-input> -->
                <!-- <div v-for="(item, index) in filesList" :key="index" class="fileNames">
                  <div style="cursor: pointer">
                    <img v-if="item.fileType == 'img'" style="margin-right: 8px" src="../../../assets/images/img_icon.svg"/>
                    <img v-if="item.fileType != 'img'" style="margin-right: 8px" src="../../../assets/images/file_icon.svg"/>
                    {{ item.fileName }}
                  </div>
                  <div style="cursor: pointer;" @click="() => deleteFileWork(item.fileId)">删除</div>
                </div> -->
                <div class="fileNames" v-for="(item, index) in filesList" :key="index">
                  <div><common-picture :fileUrl="item.fileUrl" :fileType="item.fileType" :fileName="item.fileName"></common-picture></div>
                  <div class="common-blue-btn" @click="() => deleteFileWork(item.fileId)">删除</div>
                </div>
                <div class="con-rec">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFileFlow" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button class="common-screen-btn" type="primary" @click="() => shopAddLog()" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
     <!-- 店铺改价 -->
    <el-dialog v-loading="changePriceLoad" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="店铺改价" :append-to-body="true" :visible.sync="editVisible" width="530px" :close-on-click-modal="false">
      <div>
        <div>订单应收金额: {{shopInfo.orderPrice || '0'}} 元</div>
        <br/>
        <el-table :data="shopTableData" border style="width: 100%">
          <el-table-column prop="shopName" :formatter="tableColumn" label="店铺名称"></el-table-column>
          <el-table-column prop="platformName" :formatter="tableColumn" label="所属平台"></el-table-column>
          <el-table-column prop="categoryName" :formatter="tableColumn" label="所属类目"></el-table-column>
          <el-table-column label="店铺金额">
            <template slot-scope="scope">
              <div>
                <el-input v-model="shopTableData[scope.$index].price" placeholder="请填写金额"></el-input>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="editVisible = false">取 消</el-button>
        <el-popconfirm
          title="确定修改吗？"
          @confirm="chaneShopPrice()"
        >
        <el-button slot="reference" class="common-screen-btn" type="primary">确 定</el-button>
        </el-popconfirm>
      </span>
    </el-dialog>
    <!-- 设置目标均值 -->
    <el-dialog
      title="设置目标均值"
      :visible.sync="setDialogVisible"
      width="30%"
      append-to-body
      :before-close="setHandleClose">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="店铺名称：">
          <el-input v-model="form.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="是否考核均值：">
          <el-radio-group v-model="form.resource">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
          <ul v-show="form.resource == 1" class="ulline">
              <li><span class="lione">考核项目</span><span class="litwo licolor">月均值设置</span></li>
              <li><span class="lione">首响</span><span class="litwo"><el-input v-model="form.firstTime" type="number" class="ulipt" placeholder="请输入"></el-input>s</span></li>
              <li><span class="lione">平响</span><span class="litwo"><el-input v-model="form.avgTime" type="number" class="ulipt" placeholder="请输入"></el-input>s</span></li>
              <li><span class="lione">问答比</span><span class="litwo"><el-input v-model="form.questionAnswerRatio" type="number" class="ulipt" placeholder="请输入"></el-input>%</span></li>
              <li><span class="lione">三分钟回复率</span><span class="litwo"><el-input v-model="form.thereTimeRatio" type="number" class="ulipt" placeholder="请输入"></el-input>%</span></li>
              <li><span class="lione">五分钟回复率</span><span class="litwo"><el-input v-model="form.fiveTimeRatio" type="number" class="ulipt" placeholder="请输入"></el-input>%</span></li>
            </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => {setHandleClose()}">取 消</el-button>
        <el-button type="primary" @click="() => { sendData() }">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { shopAddLog, shopOrderList, shopDetail, editShopDetail, uploadShopFile, deleteShopFile, shopSerList, getPlatList, defaultData, addData, shopDelete, list, subaccountListNew, orderShopList, chaneShopPrice } from "../../../service/customer.js";
import { upload } from "../../../service/upload.js";
import { workLog, selectCategory, selectPlatform } from "../../../service/common.js"
import { tableColumn, DateTransform, decimalNull, Config,getLatelyDay } from "../../../utils/index.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import CommonPicture from "../../../components/common/commonPicture.vue"
// import CommonLatelyDate from "../../../components/common/commonLatelyDate.vue"
import { systemServiceData } from "../../../service/dataInfo.js";
import systemSerDetail from "../../dataInfo/systemSerDetail.vue"

export default {
  props: ['drawer', 'userList','shopOrder'],
  components: { CommonWorkLog, CommonPicture,systemSerDetail },
  data() {
    return {
      Config,
      tableColumn,
      DateTransform,
      tableRow: {}, // 表格数据
      shopInfo: {},
      loading: false,
      fileList: [],
      orderTable: [],
      paginationOrder: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      orderParams: {
        pageNum: 1,
        pageSize: 10
      },
      serTable: [],
      paginationSer: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      serParams: {
        pageNum: 1,
        pageSize: 10
      },
      followRecordList: [], // 工作日志
      filesList: [], // 工作记录文件
      isShowInput: '',
      editRuleForm: {
        platId: [],
        startTime: "",
        endTime: "",
        contactName: '',
        contactPhone: '',
        busineseUserIds: [],
        warnTime: '',
        beforeDay: '',
        shopName: '',
        categoryId: '',
        platformId: '',
        agreeSubNum: '',
        conslutDayNum: ''
      },
      editRules: { // 校验规则
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }],
      },
      editParams: {
        filedName: "",
        filedValue: "",
      },
      activeName: "about", // tabs
      activeTab: this.$route.query.active? this.$route.query.active : "1", // tabs
      recordContent: "", // 跟进记录内容
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
          pageNum: 1,
          pageSize: 10,
          name: ''
      },
      serveParams: {
        startDate: this.$route.query.startDate? this.$route.query.startDate:DateTransform(getLatelyDay(30)[0]),
        endDate: this.$route.query.endDate? this.$route.query.endDate:DateTransform(getLatelyDay(30)[1])
      },
      commonRadio: this.$route.query.commonRadio? this.$route.query.commonRadio:2,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      serveData: {}, // 店铺服务数据
      setDialogVisible: false,
      form: {
        name: '',
        resource: 1,
        firstTime: "",
        avgTime: "",
        questionAnswerRatio: "",
        thereTimeRatio: "",
        fiveTimeRatio: "",
      },
      flag: false,
      setTableData: {},
      deData: {},
      childparams: { //zi参数
        pageNum: 1,
        pageSize: 10  
      },
      //子账号分页
      childPagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      childData: [], //子账号列表
      editVisible: false, // 店铺调价弹窗
      shopTableData: [], // 店铺调价列表
      changePriceLoad: false,
      shopOrderId: "",
      selectChange: false,
      pickerStart: {},
      pickerEnd: {}
    };
  },
  created() {
    // this.getPlatList()
    this.selectPlatform();
    this.selectCategory();
  },
  methods: {
    async chaneShopPrice() { // 店铺调价-提交
      let shopTableData = [...this.shopTableData]
      let chaneShopPriceList = []
      let priceAll = 0
      shopTableData.map((item) => {
        priceAll += Number(item.price)
        chaneShopPriceList.push({shopId: item.id, price: item.price})
      })
      if (priceAll > Number(this.shopInfo.orderPrice)) {
        return this.$message.error(`当前店铺金额最高可调为 ${this.shopInfo.orderPrice}元`)
      }
      let params = {
        orderId: this.shopInfo.orderId,
        chaneShopPriceList: chaneShopPriceList
      }
      this.changePriceLoad = true
      let { code } = await chaneShopPrice(params)
      this.changePriceLoad = false
      if (code == 200) {
        this.$message.success('改价成功')
        this.shopDetail(this.tableRow.id)
        this.workLog(this.tableRow.id)
        this.editVisible = false
      }
    },
    async editShow() { // 显示店铺调价
      if (this.shopInfo.orderId == 0) { return this.$message.error('该店铺订单已过期，无法调价') }
      let { data } = await orderShopList({pageNum: -1, orderId: this.shopInfo.orderId})
      this.shopTableData = data
      this.editVisible = true
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        this.$message.error("粘贴内容非图片");
        return;
      }
      this.chooseFileFlow(file, 'copy')
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        categoryParams.pageNum = -1 // 不分页
        let { data } = await selectCategory(categoryParams)
        // this.categoryList = data.list
        this.categoryList = data
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
    },
    openNewWin(url) {
      window.open(url)
    },
    logoFun () { // 更换头像
      if (this.$refs.uploadLogo) {
        this.$refs.uploadLogo.click()
      }
    },
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split('.')[ file.name.split('.').length - 1];
      if (file.size / 1024 > 1024 * 50) {
          e.target.value = "";
          return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.imgType.includes(format)) {
          e.target.value = "";
          return this.$message.error(`只能上传 ${Config.fileMessage.imgType} 格式的文件`)
      }
      let { data } = await upload({ file: e.target.files[0] });
      this.editRuleForm['shopLogo'] = data.filename
      this.editParams.filedName = 'shopLogo'
      this.editShopDetail('shopLogo')
    },
    async getPlatList() { // 所属平台/类目
      let { data } = await getPlatList({ pageNum: -1 });
      this.platList = data;
    },
    async shopDetail (id) { // 店铺详情
        let { data } = await shopDetail({ orderId: this.shopOrderId }, id)
        if (data.shopMap.platformId) {
          if (data.shopMap.categoryId) {
            data.shopMap.platId = [Number(data.shopMap.platformId), Number(data.shopMap.categoryId)]
          } else {
            data.shopMap.platId = [Number(data.shopMap.platformId)]
          }
        } else {
          data.shopMap.platId = []
        }
        if (data.shopMap.busineseUserIds) {
          data.shopMap.busineseUserIds = data.shopMap.busineseUserIds.split(',')
        } else {
          data.shopMap.busineseUserIds = []
        }
        this.shopInfo = data.shopMap
        this.form.name = data.shopMap.shopName
        if (data.shopMap.fileUrl && data.shopMap.fileName) {
            this.fileList = [{url: data.shopMap.fileUrl, name: data.shopMap.fileName, id: data.shopMap.fileId}]
        } else {
            this.fileList = []
        }
    },
    getDataInfo(row) { // 父组件传默认值
      if (this.shopOrder && this.shopOrder.length) {
        this.shopOrderId = this.shopOrder[0].id
      }
      this.tableRow = row
      this.shopDetail(row.id)
      this.shopOrderList(row.id)
      this.shopSerList(row.id)
      this.workLog(row.id)
    },
    async deleteFile(item) { // 删除文档
      let params = {
        shopId: this.tableRow.id,
        filedId: item.id,
        filedUrl: item.url,
        fileName: item.name
      }
      this.loading = true
      let { code } = await deleteShopFile(params)
      this.loading = false
      if (code == 200) {
        this.$message.success('删除成功')
      }
      this.shopDetail(this.tableRow.id)
      this.workLog(this.tableRow.id)
    },
    async uploadFile(e) { // 上传店铺交接文档
        let fileList = [...this.fileList]
        if (fileList && fileList.length) {
            e.target.value = "";
            return this.$message.error('交接文档不能重复上传，请删除后重新上传')
        }
        let file = e.target.files[0];
        let fileName = file.name;
        let format = file.name.split('.')[ file.name.split('.').length - 1];
        if (file.size / 1024 > 1024 * 50) {
            e.target.value = "";
            return this.$message.error('文件大小不能超过50M')
        }
        if (!Config.fileType.PWEIType.includes(format)) {
            e.target.value = "";
            return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
        }
        this.loading = true
        let resData = (await upload({ file: file })).data;
        let { code } = await uploadShopFile({shopId: this.tableRow.id, filedId: resData.id, fileName: fileName, filedUrl: resData.filename})
        if (code == 200) {
            this.$message.success('上传成功')
        }
        e.target.value = ''
        this.shopDetail(this.tableRow.id)
        this.workLog(this.tableRow.id)
        this.loading = false
    },
    async editShopDetail(name, isShow) { // 确认-提交修改
      if (name != 'busineseUserIds' || (name == 'busineseUserIds' && !isShow)) {
        let params = {
          shopId: this.tableRow.id,
          filedName: name,
          filedValue: this.editRuleForm[name],
        };
        
        if (name == 'platId') {
          params.filedValue = this.editRuleForm[name].join(',')
        }
        if (name == 'busineseUserIds') {
          params.filedValue = this.editRuleForm[name].join(',')
        }
        if (this.editParams.filedName) {
          if (name == 'shopLogo') {
            this.loading = true
            await editShopDetail(params);
            this.loading = false
            this.shopDetail(this.tableRow.id)
            this.workLog(this.tableRow.id)
            this.editParams = {
              filedName: "",
              filedValue: "",
            };
          } else {
            this.$refs["editRuleForm"].validateField(name, async (error) => {
              if (!error) {
                this.loading = true
                await editShopDetail(params);
                this.loading = false
                this.shopDetail(this.tableRow.id)
                this.workLog(this.tableRow.id)
                this.editParams = {
                  filedName: "",
                  filedValue: "",
                };
              } else {
                return false;
              }
            });
          }
        }
      }
        
    },
    editCancelBtn(name) { // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: "",
          };
          this.isShowInput = ''
        }
      }, 500)
    },
    async shopOrderList (id) { // 订单店铺-列表
        let orderParams = {...this.orderParams}
        orderParams.id = id
        if(this.shopOrderId){
          orderParams.orderId = this.shopOrderId
        }
        let { data } = await shopOrderList(orderParams)
        this.orderTable = data.records
        this.paginationOrder.total = data.total;
    },
    pageFunOrder(e, type) { // 订单分页
      if (type == "pageSize") {
        this.orderParams.pageSize = e;
      } else {
        this.orderParams.pageNum = e;
      }
      this.shopOrderList(this.tableRow.id);
    },
    async shopSerList (id) { // 历史客服
        let serParams = {...this.serParams}
        serParams.id = id
        if(this.shopOrderId){
          serParams.orderId = this.shopOrderId
        }
        let { data } = await shopSerList(serParams)
        this.serTable = data.records
        this.paginationSer.total = data.total;
    },
    pageFunSer(e, type) { // 客服列表分页
      if (type == "pageSize") {
        this.serParams.pageSize = e;
      } else {
        this.serParams.pageNum = e;
      }
      this.shopSerList(this.tableRow.id);
    },
    download(url) {
      // 下载
      window.location.href = url
    },
    deleteFileWork (id) {
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 5})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = resData
    },
    async chooseFileFlow(e, type) { // 订单工作日志文件上传
      let file
      if (type != 'copy') {
        file = e.target.files[0];
      } else {
        file = e
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: Config.fileType.imgType.includes(file.name.split('.')[file.name.split('.').length - 1]) ? 'imgText' : 'file',
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      // if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
      //   fileItem.fileType = "img";
      // }
      this.filesList.push(fileItem);
      if (type != 'copy') { e.target.value = "" }
    },
    async shopAddLog() { // 工作记录发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
        shopId: this.tableRow.id,
        content: this.recordContent,
        fileIds: filesId.join(','),
      };
      this.loading = true
      await shopAddLog(params);
      this.workLog(this.tableRow.id)
      this.loading = false
      this.filesList = []
      this.recordContent = "";
      this.$message.success("添加成功");
    },
    editBtn(name, value) {
      if (name == 'startTime') {
        let shopOrderItem = this.shopOrder.filter((item) => { return item.id == this.shopOrderId })[0]
        this.pickerStart = {
          disabledDate: (time) => {
            return time.getTime() < new Date(shopOrderItem.startTime).getTime() - (1000 * 60 * 60 * 24)
          }
        }
      }
      if (name == 'endTime') {
        let shopOrderItem = this.shopOrder.filter((item) => { return item.id == this.shopOrderId })[0]
        this.pickerEnd = {
          disabledDate: (time) => {
            return time.getTime() > new Date(shopOrderItem.endTime).getTime()
          }
        }
      }
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value,
            };
            this.editRuleForm[name] = value;
            resolve()
          }).then(() => {
            if (this.$refs[name]) {
              this.$refs[name].focus()
            }
          }).then(() => {
            this.isShowInput = name
          })
        } else {
          return;
        }
      });
    },
    handleClose(done) {
      this.$emit("handleClose", done);
      this.activeTab = "1"
      this.commonRadio = 2
    },
    handleClick(tab) {
      if (tab.name == 1) { // 订单
        this.shopOrderList(this.tableRow.id)
      } else if(tab.name == 2){ // 历史客服
        this.shopSerList(this.tableRow.id)
      } else if(tab.name == 3){ // 服务均值
        this.systemServiceData()
        this.getSetList().then(()=> {
          this.getDefaultData();
        })
      }else if(tab.name == 4){ // 子账号
        this.getChildList()
      }
    },
    loadFun(value) {
      this.$emit("loadFun", value);
    },
    commonGetDate (startTime, endTime, commonRadio) {
      this.serveParams.startDate = startTime
      this.serveParams.endDate = endTime
      this.commonRadio = commonRadio
      this.systemServiceData()
      this.$refs.serDetail.systemServeDetail(startTime,endTime)
    },
    resetBtn() {
      this.commonRadio = 2
      this.serveParams = {
        startDate: DateTransform(getLatelyDay(30)[0]),
        endDate: DateTransform(getLatelyDay(30)[1])
      };
      if (this.rolesCompany && this.rolesCompany.length) {
        this.serveParams.companyId = this.rolesCompany[0].id;
      }
      this.$refs.commonReset.resetFun(2, DateTransform(getLatelyDay(30)[0]), DateTransform(getLatelyDay(30)[1]))
      this.systemServiceData();
    },
    async systemServiceData() {
      // 索取服务数据
      let params = { ...this.serveParams };
      params.shopId = this.tableRow.id
      if(this.shopOrderId){
          params.orderId = this.shopOrderId
        }
      this.loading = true;
      let resData = (await systemServiceData(params)).data;
      this.serveData = resData;
      this.loading = false;
    },
    setHandleClose(){
      this.form.resource = 1
      this.form.firstTime = this.flag?  this.setTableData.firstTime:this.deData.firstTime
      this.form.avgTime =  this.flag?  this.setTableData.avgTime:this.deData.avgTime
      this.form.questionAnswerRatio = this.flag?  this.setTableData.questionAnswerRatio:this.deData.questionAnswerRatio
      this.form.thereTimeRatio = this.flag?  this.setTableData.thereTimeRatio:this.deData.thereTimeRatio
      this.form.fiveTimeRatio = this.flag?  this.setTableData.fiveTimeRatio:this.deData.fiveTimeRatio
      this.setDialogVisible = false
    },
    openUrl(){
      window.open('/data/customer-service-data')
    },
    async getDefaultData(){ //获取服务均值默认值
      const { data } = await defaultData()
      this.deData = data
      this.form.firstTime = this.flag?  this.setTableData.firstTime:data.firstTime
      this.form.avgTime = this.flag?  this.setTableData.avgTime:data.avgTime
      this.form.questionAnswerRatio = this.flag?  this.setTableData.questionAnswerRatio:data.questionAnswerRatio
      this.form.thereTimeRatio = this.flag?  this.setTableData.thereTimeRatio:data.thereTimeRatio
      this.form.fiveTimeRatio = this.flag?  this.setTableData.fiveTimeRatio:data.fiveTimeRatio
    },
    async sendData() {
      if(this.form.resource == 1){
        let params = {...this.form}
        params.shopId = this.tableRow.id
        delete params.name 
        delete params.resource
        const res = await addData(params)
        if(res.code == 200){
          this.$message.success('设置成功')
        }
      }else{
        const res = await shopDelete({shopId: this.tableRow.id})
        if(res.code == 200){
          this.$message.success('操作成功')
        }
        this.form.firstTime = this.deData.firstTime
        this.form.avgTime = this.deData.avgTime
        this.form.questionAnswerRatio = this.deData.questionAnswerRatio
        this.form.thereTimeRatio = this.deData.thereTimeRatio
        this.form.fiveTimeRatio = this.deData.fiveTimeRatio
      }
      this.setDialogVisible = false
      this.form.resource = 1
      this.getSetList()
    },
    async getSetList(){ //获取设置的目标值
      this.flag = false
      const { data } = await list({shopId: this.tableRow.id})
      this.setTableData = data
      if(data&&Object.values(data).length>0){
        this.flag = true
      }else{
        this.flag = false
      }
      this.$refs.serDetail.systemServeDetail(this.serveParams.startDate,this.serveParams.endDate)
    },
    async getChildList() {
      let params = {...this.childparams}
      params.companyId = this.rolesCompany[0].id
      params.id = this.tableRow.id
      if(this.shopOrderId){
          params.orderId = this.shopOrderId
      }
      const { data } = await subaccountListNew(params)
      this.childData = data.records
      this.childPagination.total = data.total
    },
    pageFunChild(e, type) { // 订单分页
      if (type == "pageSize") {
        this.childparams.pageSize = e;
      } else {
        this.childparams.pageNum = e;
      }
      this.getChildList();
    },
    changeOrder(val){
        this.selectChange = true
        this.shopOrder.forEach(element => {
          if(element.id == val){
            this.serveParams.startDate = element.startTime
            this.serveParams.endDate = element.endTime
          }
        });
        if(this.activeTab == '1'){
          this.shopOrderList(this.tableRow.id)
        }

        if(this.activeTab == '2'){
          this.shopSerList(this.tableRow.id)
        }
        

        if(this.activeTab == '3'){ //服务均值
          this.systemServiceData()
          this.getSetList().then(()=> {
            this.getDefaultData();
          })
        }
        if(this.activeTab == '4'){ //子账号列表
          this.getChildList()
        }
        this.shopDetail(this.tableRow.id)
    }
  },
};
</script>
<style lang="less" scoped>
/deep/.el-table th {
  background: #eee;
}
.shopDetail-drawer {
  .modifyPrice {
    display: inline-block;
    vertical-align: middle;
    padding: 0 26px 0 6px;
    height: 28px;
    line-height: 28px;
    margin-left: 8px;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -66px;
      content: '店铺调价';
      color: #1890ff;
      cursor: pointer;
    }
  }
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .order {
        padding-bottom: 32px;
        /deep/.el-input__inner{
          width: 360px;
        }
      }
      .line {
        height: 1px;
        background-color: #e8e8e8;
        margin: 0 -3% 24px;
      }
      .left-top {
        display: flex;
        justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;
        .shopLogo-container {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
          }
          .common-iconImg-MR {
            width: 64px;
            height: 64px;
            font-size: 50px;
            line-height: 64px;
          }
        }
        .shopName-custName {
          display: inline-block;
          margin: 0 16px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: #333;
              font-size: 18px;
            }
            &:nth-of-type(2) {
              color: #999;
              font-size: 14px;
            }
          }
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #F98787;
          color: #F98787;
          margin-left: 16px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99% center
            #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-bottom: 18px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
  .common-screen-container{
    margin: 0;
    margin-bottom: 8px;
    .set{
      height: 32px;
      line-height: 8px;
      margin-left: 28px;
    }
  }
  .tableshow{
    text-align: right;
    margin-bottom: 32px;
    .square{
      display: inline-block;
      width: 10px;
      height: 10px;
      &:not(:first-child){
        margin-left: 40px;
      }
    }
    .no{
      background-color: #757775;
    }
    .y{
      background-color: #56C51E;
    }
    .n{
      background-color: #F5232D;
    }
  }
  .systemServiceData-content {
    margin: 48px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .systemServiceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 43px;
        line-height: 43px;
        font-weight: 600;
      }
      td {
        height: 58px;
        line-height: 58px;
      }
    }
  }
}
.ulline{
        padding-left: 32px;
        border-left: 3px solid #1890ff;
        .lione{
          display: inline-block;
          width: 150px;
          color: #101010;
        }
        .litwo{
          color: #989998;
          font-size: 14px;
        }
        .licolor{
            color: #101010;
        }
        .ulipt{
          width: 92px;
        }
}
.fontRed{
  color: #F24433;
}
.fontGreen{
  color: #56C51E;
}
</style>
